<template>
  <div>
    <Pane :bottom="0" :showRemarks="false">
      <a-tabs
        defaultActiveKey="0"
        :tabBarStyle="{
          margin: '0',
        }"
      >
        <a-tab-pane key="0" tab="处罚通知"> </a-tab-pane>
      </a-tabs>

      <template v-slot:extra>
        <ExportDownload
          :isView="isView"
          width="50%"
          :url="imgUrl"
          @exportForm="exportForm"
          @download="download"
        />
      </template>
    </Pane>

    <div class="container">
      <a-form
        :form="form"
        @submit="handleSubmit"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 18 }"
        :colon="false"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item
              label="当前项目"
              class="default-highlight"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input :disabled="true" :placeholder="designCode"></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="工程名称" class="default-highlight">
              <a-input :disabled="true" :placeholder="projectName"></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="项目名称">
              <a-input
                v-decorator="[
                  'constructionName',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入',
                      },
                    ],
                  },
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item
              label="受罚单位"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input
                v-decorator="[
                  'penaltyCompany',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入',
                      },
                    ],
                  },
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item
              label="违章地点"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input
                v-decorator="[
                  'accidentSite',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入',
                      },
                    ],
                  },
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item
              label="罚款金额(万)"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input-number
                :formatter="$inputNumberFormat"
                style="width: 100%"
                v-decorator="[
                  'penaltyAmount',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入',
                      },
                    ],
                  },
                ]"
              ></a-input-number>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item
              label="违章（单位）人员"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-input
                v-decorator="[
                  'accidentObject',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入',
                      },
                    ],
                  },
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="违章事项">
              <a-input
                v-decorator="[
                  'penaltyDesc',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入',
                      },
                    ],
                  },
                ]"
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <span slot="label" class="">附件</span>
              <div class="link-list" v-decorator="['file']">
                <a-checkbox-group
                  :value="selectedFileList"
                  @change="onSelectFile"
                  v-if="fileList.length !== 0"
                >
                  <div class="link" v-for="item in fileList" :key="item.id">
                    <a-checkbox :value="item.id">
                      <a target="_blank" download :href="item.completePath">{{
                        item.name
                      }}</a>
                    </a-checkbox>
                  </div>
                </a-checkbox-group>
                <div
                  style="color: #ccc"
                  v-else-if="fileList.length === 0 && id"
                >
                  无
                </div>
                <div style="color: #ccc; line-height: 1.5" v-else>
                  违章照片<br />
                  《处罚通知书》
                </div>

                <div class="control-bar">
                  <FileUpload @uploaded="uploaded">
                    <div class="control">
                      <a-icon type="upload" />
                    </div>
                  </FileUpload>
                  <div class="control" @click="deleteFile">
                    <a-icon type="delete" />
                  </div>
                </div>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <div class="center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
            <a-button html-type="submit" type="primary" v-if="!isView"
              >提交</a-button
            >
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import ExportDownload from "@/views/epc/components/ExportDownload";
import FileUpload from "@/components/file-upload";
import { saveAs } from "file-saver";

import { fetchDetail } from "@/api/epc";
// eslint-disable-next-line no-unused-vars
import {
  add,
  edit,
  fetchDetail as fetchFileDetail,
  exportFile,
  downloadTemplate,
} from "@/api/epc/construction";

import { mapGetters } from "vuex";

export default {
  name: "ConstruVio",
  components: {
    ExportDownload,
    FileUpload,
  },
  data() {
    return {
      imgUrl: require("@/assets/epc/approvalFlow/4-10vialation.png"),

      activeKey: "0",
      stage: "epc_construction_security_rule",
      isView: false, //控制导出按钮是否显示

      form: this.$form.createForm(this),
      pid: "",
      id: "",
      hid: "",
      projectName: "",
      designCode: "",

      fileList: [],
      selectedFileList: [],

      fileType: "construction_security_penalty_announcement", //文件导出相关
    };
  },
  computed: {
    ...mapGetters("setting", ["findSingleDictName"]),
    fileTypeName() {
      return this.findSingleDictName("epc_file_export_type", this.fileType);
    },
  },
  mounted() {
    const { query } = this.$route;
    const { pid, id, hid, isView } = query || {};

    this.pid = pid;
    this.id = id;
    this.hid = hid;
    this.isView = Boolean(isView) || false;

    if (this.id) {
      this.getDetail(); // 从文件列表进入时，获取详情
    }
    this.getName(); // 获取项目名称，设计编号
    console.log("fileType", this.fileType);
    console.log("stage", this.stage);
  },
  methods: {
    getDetail() {
      fetchFileDetail({ id: this.id }).then((res) => {
        if (!res.id) return;

        this.form.setFieldsValue({
          constructionName: res.constructionName,
          penaltyCompany: res.penaltyCompany,
          accidentSite: res.accidentSite,
          penaltyAmount: res.penaltyAmount,
          accidentObject: res.accidentObject,
          penaltyDesc: res.penaltyDesc,
          file: res.attachmentList,
        });

        if (res.attachmentList) {
          this.fileList = res.attachmentList;
        }
      });
    },
    getName() {
      fetchDetail(this.pid).then((res) => {
        this.projectName = res.name;
        this.designCode = res.designCode;
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // 如果没有id ，则新增
          delete values.file;

          if (!this.id) {
            console.log("新增");
            console.log(this.fileList);
            console.log(values);

            add({
              ...values,
              pid: this.pid,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: this.fileList,
              type: this.stage,
            }).then(() => {
              this.$close(this.$route.path);
            });
          } else {
            edit({
              ...values,
              id: this.id,
              historyId: this.hid,
              pid: this.pid,
              projectName: this.projectName,
              designCode: this.designCode,
              attachments: this.fileList,
              type: this.stage,
            }).then(() => {
              this.$close(this.$route.path);
            });
          }
        }
      });
    },

    onSelectFile(values) {
      this.selectedFileList = values;
    },
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];

      if (this.fileList.length > 0) {
        this.form.setFieldsValue({
          file: true,
        });
      }
    },
    deleteFile() {
      if (this.selectedFileList.length === 0) {
        this.$message.error("请选择附件！");
        return;
      }
      this.selectedFileList.forEach((item) => {
        const index = this.fileList.findIndex((file) => file.id === item);
        if (index > -1) {
          this.fileList.splice(index, 1);
        }
      });
      this.selectedFileList = [];

      if (this.fileList.length === 0) {
        this.form.setFieldsValue({
          file: "",
        });
      }
    },

    exportForm() {
      exportFile({ id: this.id, fileExportType: this.fileType })
        .then((blob) => {
          saveAs(blob, `${this.projectName}_${this.fileTypeName}.docx`);
        })
        .catch();
    },
    download() {
      downloadTemplate(this.fileType)
        .then((blob) => {
          saveAs(blob, `${this.fileTypeName}模版.docx`);
        })
        .catch();
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 20px;
}

.center {
  margin-top: 80px;
}
</style>
