import request from "../request";
import download from "../download";

export function fetchList(params) {
  return request({
    url: "/market-service/epc/project-start/project/list",
    params,
  });
}

export function add(data) {
  return request({
    url: "/market-service/epc/project-start/project/add",
    method: "post",
    data,
  });
}
//

export function validate(params) {
  return request({
    url: "/market-service/epc/project-start/project/add/valid",
    params,
  });
}

// 暂存项目
export function save(data) {
  return request({
    url: "/market-service/epc/project-start/project/add/temp",
    method: "post",
    data,
  });
}
//
export function fetchSaveData() {
  return request({
    url: "/market-service/epc/project-start/project/query/temp",
  });
}

export function fetchDetail(params) {
  return request({
    url: `/market-service/epc/project-start/project/query/${params}`,
  });
}

export function edit(data) {
  return request({
    url: "/market-service/epc/project-start/project/update",
    method: "post",
    data,
  });
}

export function remove(data) {
  return request({
    url: "/market-service/epc/project-start/project/delete",
    method: "post",
    data,
  });
}

export function removeBatch(data) {
  return request({
    url: "/market-service/epc/project-start/project/delete_batch",
    method: "post",
    data,
  });
}

export function fetchMemberList(data) {
  return request({
    url: "/market-service/epc/project-start/project-member-template/list",
    data,
  });
}

// 获取项目的成员列表
export function fetchPrjMemberList(params) {
  return request({
    url: `market-service/epc/project-start/project-member/list?pageNum=${params.pageNum}&pageSize=${params.pageSize}&pid=${params.pid}`,
  });
}

// 导出组织机构
export function exportFile(params) {
  return download({
    url: `/market-service/epc/project-start/project/export/${params.pid}`,
  });
}
// 下载组建模版
export function downloadTemplate() {
  return download({
    url: "/market-service/epc/project-start/project/export/template",
  });
}

// 增加模版
export function addTemplate(data) {
  return request({
    url: "/market-service/epc/project-start/project-member-template/update",
    method: "post",
    data,
  });
}

// 批量导出
export function exportBatch(data) {
  return download({
    url: "/market-service/epc/common/stages_files/export_download",
    method: "post",
    data,
  });
}

// 获取有提交权限的
export function fetchMembers(params) {
  return request({
    url: `/market-service/epc/project-start/project-member/list`,
    params,
  });
}

// 导入历史数据
export function importHistory(data) {
  return request({
    url: '/market-service/epc/balance/contract/import',
    method: "post",
    data,
  });
}

