import request from "@/api/request";
import download from "@/api/download";

export function add(data) {
  return request({
    url: "/market-service/epc/construction/add",
    method: "post",
    data,
  });
}

export function edit(data) {
  return request({
    url: "/market-service/epc/construction/update",
    method: "post",
    data,
  });
}

export function fetchDetail(params) {
  return request({
    url: `/market-service/epc/construction/query/${params.id}`,
  });
}

export function fetchDetailByPid(params) {
  return request({
    url: `/market-service/epc/construction/queryByPid/${params.id}/${params.stage}`,
  });
}

// 下载模版
export function downloadTemplate(params) {
  return download({
    url: `/market-service/epc/construction/export/template?fileExportType=${params}`,
  });
}
// 导出
export function exportFile(data) {
  return download({
    url: `/market-service/epc/construction/export`,
    data,
  });
}

// 暂存
export function save(data) {
  return request({
    url: `/market-service/epc/construction/add/temp`,
    method: "post",
    data,
  });
}
export function fetchSaveData() {
  return request({
    url: `/market-service/epc/construction/query/temp`,
  });
}
