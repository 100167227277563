var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane',{attrs:{"bottom":0,"showRemarks":false},scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('ExportDownload',{attrs:{"isView":_vm.isView,"width":"50%","url":_vm.imgUrl},on:{"exportForm":_vm.exportForm,"download":_vm.download}})]},proxy:true}])},[_c('a-tabs',{attrs:{"defaultActiveKey":"0","tabBarStyle":{
        margin: '0',
      }}},[_c('a-tab-pane',{key:"0",attrs:{"tab":"处罚通知"}})],1)],1),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 4 },"wrapper-col":{ span: 18 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{staticClass:"default-highlight",attrs:{"label":"当前项目","label-col":{ span: 8 },"wrapper-col":{ span: 12 }}},[_c('a-input',{attrs:{"disabled":true,"placeholder":_vm.designCode}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{staticClass:"default-highlight",attrs:{"label":"工程名称"}},[_c('a-input',{attrs:{"disabled":true,"placeholder":_vm.projectName}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"项目名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'constructionName',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入',
                    },
                  ],
                },
              ]),expression:"[\n                'constructionName',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"受罚单位","label-col":{ span: 8 },"wrapper-col":{ span: 12 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'penaltyCompany',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入',
                    },
                  ],
                },
              ]),expression:"[\n                'penaltyCompany',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"违章地点","label-col":{ span: 8 },"wrapper-col":{ span: 12 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'accidentSite',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入',
                    },
                  ],
                },
              ]),expression:"[\n                'accidentSite',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"罚款金额(万)","label-col":{ span: 8 },"wrapper-col":{ span: 12 }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'penaltyAmount',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入',
                    },
                  ],
                },
              ]),expression:"[\n                'penaltyAmount',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入',\n                    },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"100%"},attrs:{"formatter":_vm.$inputNumberFormat}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"违章（单位）人员","label-col":{ span: 8 },"wrapper-col":{ span: 12 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'accidentObject',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入',
                    },
                  ],
                },
              ]),expression:"[\n                'accidentObject',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"违章事项"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'penaltyDesc',
                {
                  rules: [
                    {
                      required: true,
                      message: '请输入',
                    },
                  ],
                },
              ]),expression:"[\n                'penaltyDesc',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: '请输入',\n                    },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("附件")]),_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:(['file']),expression:"['file']"}],staticClass:"link-list"},[(_vm.fileList.length !== 0)?_c('a-checkbox-group',{attrs:{"value":_vm.selectedFileList},on:{"change":_vm.onSelectFile}},_vm._l((_vm.fileList),function(item){return _c('div',{key:item.id,staticClass:"link"},[_c('a-checkbox',{attrs:{"value":item.id}},[_c('a',{attrs:{"target":"_blank","download":"","href":item.completePath}},[_vm._v(_vm._s(item.name))])])],1)}),0):(_vm.fileList.length === 0 && _vm.id)?_c('div',{staticStyle:{"color":"#ccc"}},[_vm._v(" 无 ")]):_c('div',{staticStyle:{"color":"#ccc","line-height":"1.5"}},[_vm._v(" 违章照片"),_c('br'),_vm._v(" 《处罚通知书》 ")]),_c('div',{staticClass:"control-bar"},[_c('FileUpload',{on:{"uploaded":_vm.uploaded}},[_c('div',{staticClass:"control"},[_c('a-icon',{attrs:{"type":"upload"}})],1)]),_c('div',{staticClass:"control",on:{"click":_vm.deleteFile}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1)],1)])],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")]),(!_vm.isView)?_c('a-button',{attrs:{"html-type":"submit","type":"primary"}},[_vm._v("提交")]):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }