<template>
  <div>
    <div class="export-download" v-if="single">
      <div @click="visible = true" class="progress" v-if="!processDisable">
        <img src="@/assets/epc/progress.jpg" alt="" />
        <a>流程查看</a>
      </div>
      <div class="button" @click="exportForm" v-if="isView">
        <img class="export" :src="exportImage" alt="" />
        <span>导出文档</span>
      </div>
      <div class="button" @click="download">
        <img class="download" :src="downloadImage" alt="" />
        <span>下载模板</span>
      </div>
    </div>
    <div class="export-download" v-else>
      <div @click="visible = true" class="progress" v-if="!processDisable">
        <img src="@/assets/epc/progress.jpg" alt="" />
        <a>流程查看</a>
      </div>

      <a-dropdown v-if="isView">
        <div class="button">
          <img :src="exportImage" />
          <span>导出文档</span>
        </div>
        <div slot="overlay" class="content">
          <div v-for="(item, index) in fileList" :key="index">
            <span @click="exportForm(item)">《{{ item.name }}》</span>
          </div>
        </div>
      </a-dropdown>
      <a-dropdown>
        <div class="button">
          <img :src="downloadImage" />
          <span>下载模板</span>
        </div>
        <div slot="overlay" class="content">
          <div v-for="(item, index) in fileList" :key="index">
            <span @click="download(item)">《{{ item.name }}》</span>
          </div>
        </div>
      </a-dropdown>
    </div>

    <a-modal
      :visible="visible"
      title="流程图"
      dialogClass="modal"
      cancelText="关闭"
      width="800px"
      :footer="null"
      @cancel="visible = false"
    >
      <a :href="url" title="点击查看大图" target="_blank">
        <img :src="url" :style="{ width: width ? width : '100%' }" />
      </a>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    isView: {
      // 是否为查看页面，若为查看，才有导出
      type: Boolean,
      default: false,
    },
    url: {
      //审批图片
      type: String,
      default: "",
    },
    processDisable: {
      //是否包含审批留图片
      type: Boolean,
      default: false,
    },
    single: {
      //导出和下载模版是否有多个
      type: Boolean,
      default: true,
    },
    fileList: {
      //有多个时的文件列表
      type: Array,
      default: () => [],
    },
    width: {
      //图片宽度
      type: String,
      default: "",
    },
  },
  data() {
    return {
      exportImage: require("@/assets/epc/export.png"),
      downloadImage: require("@/assets/epc/download.png"),
      visible: false,
    };
  },
  methods: {
    exportForm(item) {
      this.$emit("exportForm", item);
    },
    download(item) {
      this.$emit("download", item);
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  background-color: #fff;
  border: 1px solid #ccc;
  div {
    padding: 10px 5px;
    &:hover {
      background-color: #1890ff;
      color: #fff;
      cursor: pointer;
    }
  }
  div:last-child {
    margin-bottom: 0;
  }
}
</style>
<style lang="less">
.modal {
  .ant-modal-body {
    text-align: center;
  }
}
</style>

